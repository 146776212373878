<template>
	<div id="mainpage" class="flex flex-col min-h-screen thank-you">

		<SiteHeader />
			
		<div class="section mainbanner-section relative pt-48 pb-16 lg:py-0 lg:h-0 min-h-screen bg-[#101010] overflow-hidden">
			<div class="flex items-center h-full overflow-hidden">
				<div class="container text-center text-white">
					<div class="space-y-5">
						<div class="">
							<div class="mb-8 text-3xl leading-none">Thank you for <span class="inline-block">getting in touch!</span></div>
						</div>
						<div class="mt-4">
							<router-link class="inline-block px-8 py-4 text-lg uppercase rounded bg-siteRed" to="/">
								Back to Homepage
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
			
		<!-- <div class="section mainbanner-section lg:min-h-screen lg:max-h-[1080px] bg-black lg:mb-[-53px]">
			<div class="lg:min-h-screen lg:max-h-[1080px] bg-cover bg-center bg-no-repeat" :style="'background-image: url(' + require(`@/assets/images/mainbanner-bg01.jpg`) + ');'">
				<div class="container pb-16 text-white pt-44 sm:pt-32 lg:pt-58">
					<div class="space-y-5">
						<div class="">
							<div class="mb-4 text-3xl leading-none lg:mb-8 lg:text-22xl">Thank you for <span class="inline-block">getting in touch!</span></div>
						</div>
						<div class="inline-block space-y-5">
							<div class="flex space-x-4 text-black lg:text-lg">
								<a href="/" class="px-6 lg:px-14 py-3 lg:py-4 rounded-full uppercase sm:tracking-[.2em] duration-200 bg-white hover:bg-siteYellow">Back to Homepage</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

		<SiteFooter />

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'thank-you',
	components: {
		SiteHeader,
		SiteFooter,
	},
	data() {
		return {
			isOpen: 0,
			isTop: true,
		}
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
		window.scrollTo(0, 0);

		// Event snippet for Conversion - Thank you Page conversion page
		// this.$gtag.event( 'conversion', { 'send_to': 'AW-349698048/VovhCNDRvsYCEIDw36YB' } );
	},
	methods: {
		handleScroll() {
			this.isTop = window.scrollY < 200;
		},
	},
}
</script>
